.mn-footer-container {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
  z-index: 1001;
  padding-bottom: 1em;
  padding-right: 12px;
  padding-left: 12px;
}

.mn-footer-container .micronaut-foundation {
  height: 80px;
  height: calc(100vw / 9);
  min-height: 60px;
  max-height: 101px;
  width: auto;
}

@media only screen and (max-width: 350px) {
  .mn-footer-container {
    padding-right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}
